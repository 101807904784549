<template>
  <div class="app-container">
    <eHeader ref="header" :query="query" :platList="platList"/>
    <!--表格渲染-->
    <el-table v-loading="loading" :data="data" size="small" stripe style="width: 100%;">
      <el-table-column prop="id" label="主键id" width="80"/>
      <el-table-column prop="siteName" label="站点名称"/>
      <el-table-column prop="partnerUserId" label="合作伙伴用户id"/>
      <el-table-column prop="partnerUserName" label="合作伙伴昵称"/>
      <el-table-column prop="platformName" label="合作平台"/>
      <el-table-column prop="platformIbId" label="平台代理号"/>
      <!-- <el-table-column prop="platformRegLink" label="合作平台注册链接" hidden/> -->
      <el-table-column prop="serverName" label="合作平台服务器"/>
      <el-table-column prop="platformLogin" label="合作平台MT4账号"/>
      <el-table-column prop="checkStatus" label="审核状态">
        <template slot-scope="scope">
          <span>{{ scope.row.checkStatus==1? '待审核':scope.row.checkStatus==2?'审核通过':'审核拒绝'}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="创建时间">
        <template slot-scope="scope">
          <span>{{ parseTime(scope.row.createTime) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180px" align="center">
        <template slot-scope="scope">
          <edit v-if="checkPermission(['ADMIN','SrCommunityPartnerRelation_ALL','SrCommunityPartnerRelation_SELECT','SrCommunityPartnerRelation_CHECK'])" :tableStatus="tableStatus" :data="scope.row" :sup_this="sup_this"/>
        </template>
      </el-table-column>
    </el-table>
    <!--分页组件-->
    <el-pagination
      :total="total"
      style="margin-top: 8px;"
      layout="total, prev, pager, next, sizes"
      @size-change="sizeChange"
      @current-change="pageChange"/>
  </div>
</template>

<script>
import checkPermission from '@/utils/permission'
import initData from '@/mixins/initData'
import { del } from '@/api/community/partnerRelation'
import { getAllSrPlatformInfo } from '@/api/community/srPlatformInfo'
import { parseTime } from '@/utils/index'
import eHeader from '@/components/community/partnerrelation/header'
import edit from '@/components/community/partnerrelation/edit'
export default {
  components: { eHeader, edit },
  mixins: [initData],
  props:{
    tableStatus: {
      type: Number,
      required: true
    },
    refresh: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      delLoading: false,
      sup_this: this,
      platList:[],
    }
  },
  watch:{
    refresh( newData,oldData){
        this.init()
    }
  },
  mounted() {
    this.getPlatformList();
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    parseTime,
    checkPermission,
    beforeInit() {
      this.url = 'community/crm/srCommunityPartnerRelation'
      const sort = 't1.id,desc'
      this.params = { page: this.page, size: this.size, sort: sort }
      const query = this.query
      const userInfo = query.userInfo;
      const platformId = query.platformId;
      const checkStatus = query.checkStatus;
      if (userInfo !== "" && userInfo !== null) {
        this.params["userInfo"] = userInfo;
      }
      if (this.$refs.header.$refs.searchcommunity.siteId) {
          this.params['siteId'] = this.$refs.header.$refs.searchcommunity.siteId
      }
      if (platformId !== "" && platformId !== null) {
        this.params["platformId"] = platformId;
      }
      if(this.tableStatus=='-1'){
        if(checkStatus!=null && checkStatus!=''){
          this.params['checkStatus'] = checkStatus
        }
      }else {
        this.params['checkStatus'] = this.tableStatus
      }
      return true
    },
    subDelete(id) {
      this.delLoading = true
      del(id).then(res => {
        this.delLoading = false
        this.$refs[id].doClose()
        this.init()
        this.$notify({
          title: '删除成功',
          type: 'success',
          duration: 2500
        })
      }).catch(err => {
        this.delLoading = false
        this.$refs[id].doClose()
        console.log(err.response.data.message)
      })
    },
    getPlatformList(){
      getAllSrPlatformInfo().then(res => {
        this.platList=res
      }).catch(err => {
        console.log(err.response.data.message)
      })
    }
  }
}
</script>

<style scoped>

</style>
