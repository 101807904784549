<template>
  <div class="head-container">
    <!-- 搜索 -->
     <el-input v-model="query.userInfo" clearable placeholder="用户ID/用户昵称" style="width: 200px;" class="filter-item" @keyup.enter.native="toQuery"/>
    <search-community ref="searchcommunity"></search-community>
    <el-select v-model="query.platformId" clearable placeholder="平台" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in platList" :key="item.id" :label="item.platformName" :value="item.id"/>
    </el-select>
    <el-select v-model="query.checkStatus" clearable placeholder="审核状态" class="filter-item" style="width: 130px" @change="toQuery">
      <el-option v-for="item in checkStatusOptions" :key="item.key" :label="item.display_name" :value="item.key"/>
    </el-select>

    <el-button class="filter-item" size="mini" type="primary" icon="el-icon-search" @click="toQuery">搜索</el-button>
  </div>
</template>

<script>
  import searchCommunity from '@/components/common/searchCommunity'
import checkPermission from '@/utils/permission' // 权限判断函数
import eForm from './form'
export default {
  components: { eForm,searchCommunity },
  props: {
    query: {
      type: Object,
      required: true
    },
    platList: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      checkStatusOptions:[
        { key: '1', display_name: '待审核' },
        { key: '2', display_name: '审核通过' },
        { key: '3', display_name: '审核拒绝' },
      ]
    }
  },
  methods: {
    checkPermission,
    toQuery() {
      this.$parent.page = 0
      this.$parent.init()
    }
  }
}
</script>
