<template>
  <div>
    <el-button v-if="this.tableStatus==1" size="mini" type="success" @click="to">审核</el-button>
    <el-button v-if="this.tableStatus!=1" size="mini" type="success" @click="to">查看</el-button>
    <eForm ref="form" :sup_this="sup_this" :is-add="false" :tableStatus="tableStatus"/>
  </div>
</template>
<script>
import eForm from './form'
export default {
  components: { eForm },
  props: {
    data: {
      type: Object,
      required: true
    },
    tableStatus: {
      type: Number,
      required: true
    },
    sup_this: {
      type: Object,
      required: true
    }
  },
  methods: {
    to() {
      const _this = this.$refs.form
      _this.form = {
        id: this.data.id,
        siteId: this.data.siteId,
        siteName: this.data.siteName,
        partnerUserId: this.data.partnerUserId,
        partnerUserName: this.data.partnerUserName,
        platformName: this.data.platformName,
        platformIbId: this.data.platformIbId,
        platformRegLink: this.data.platformRegLink,
        serverName: this.data.serverName,
        platformLogin: this.data.platformLogin,
        checkStatus: this.data.checkStatus.toString(),
        eventContent: this.data.eventContent,
        createTime: this.data.createTime
      }
      _this.dialog = true
    }
  }
}
</script>

<style scoped>
  div{
    display: inline-block;
    margin-right: 3px;
  }
</style>
