import request from '@/utils/request'

export function add(data) {
  return request({
    url: 'community/crm/srCommunityPartnerRelation',
    method: 'post',
    data
  })
}

export function del(id) {
  return request({
    url: 'community/crm/srCommunityPartnerRelation/' + id,
    method: 'delete'
  })
}

export function edit(data) {
  return request({
    url: 'community/crm/srCommunityPartnerRelation',
    method: 'put',
    data
  })
}

export function partnerAudit(params) {
  return request({
    url: '/community/crm/srCommunityPartnerRelation/partnerAudit',
    method: 'get',
    params
  })
}

export function getSrCommunityPartnerRelations(params) {
  return request({
    url: 'community/crm/srCommunityPartnerRelation',
    method: 'get',
    params
  })
}
