<template>
  <el-dialog
    :append-to-body="true"
    :visible.sync="dialog"
    :title="tableStatus==1?'审核':'查看'"
    width="750px"
  >
    <el-form ref="form" :model="form" size="small" label-width="180px">
      <el-form-item label="Id" hidden>
        <el-input v-model="form.id" style="width: 370px;" />
      </el-form-item>
      <el-form-item label="站点Id" hidden>
        <el-input v-model="form.siteId" style="width: 370px;" />
      </el-form-item>
      <el-form-item label="站点名称">
        <el-input v-model="form.siteName" style="width: 370px;" disabled />
      </el-form-item>
      <el-form-item label="合作伙伴用户id">
        <el-input v-model="form.partnerUserId" style="width: 370px;" disabled />
      </el-form-item>
      <el-form-item label="合作伙伴昵称">
        <el-input v-model="form.partnerUserName" style="width: 370px;" disabled />
      </el-form-item>
      <el-form-item label="合作平台Id" hidden>
        <el-input v-model="form.platformId" style="width: 370px;" />
      </el-form-item>
      <el-form-item label="合作平台">
        <el-input v-model="form.platformName" style="width: 370px;" disabled />
      </el-form-item>
      <el-form-item label="平台代理号">
        <el-input v-model="form.platformIbId" style="width: 370px;" disabled />
        <el-button
          v-if="tableStatus==1"
          type="primary"
          @click="getPlatformInfo(form.siteId)"
        >获取平台代理信息</el-button>
      </el-form-item>
      <el-form-item label="合作平台注册链接">
        <el-input
          type="textarea"
          autosize
          v-model="form.platformRegLink"
          style="width: 370px;"
          disabled
        />
      </el-form-item>
      <el-form-item label="合作平台服务器">
        <el-input v-model="form.serverName" style="width: 370px;" disabled />
      </el-form-item>
      <el-form-item label="合作平台MT4账号">
        <el-input v-model="form.platformLogin" style="width: 370px;" disabled />
      </el-form-item>
      <el-form-item label="审核状态">
        <el-radio-group v-model="form.checkStatus" disabled>
          <el-radio label="1">待审核</el-radio>
          <el-radio label="2">审核通过</el-radio>
          <el-radio label="3">审核拒绝</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="审核意见">
        <el-input
          type="textarea"
          autosize
          v-model="form.eventContent"
          style="width: 370px;"
          :disabled="tableStatus!=1"
        />
      </el-form-item>
      <el-form-item label="创建时间">
        <el-date-picker v-model="form.createTime" type="date" placeholder="选择日期" disabled></el-date-picker>
      </el-form-item>
    </el-form>
    <div v-if="tableStatus==1" slot="footer" class="dialog-footer">
      <el-button :loading="loading" type="success" @click="doSubmit(2)">通过</el-button>
      <el-button :loading="loading" type="danger" @click="doSubmit(3)">拒绝</el-button>
      <el-button type="text" @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { partnerAudit } from "@/api/community/partnerRelation";
import { getSrPlatformInfos } from "@/api/community/srPlatformInfo";
import { parseTime } from "@/utils/index";
export default {
  props: {
    isAdd: {
      type: Boolean,
      required: true
    },
    sup_this: {
      type: Object,
      default: null
    },
    tableStatus: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      dialog: false,
      form: {
        id: "",
        siteId: "",
        partnerUserId: "",
        partnerUserName: "",
        cooperPlatformCode: "",
        platformIbId: "",
        platformRegLink: "",
        platformServerId: "",
        platformLogin: "",
        platformLoginPwd: "",
        checkStatus: "",
        eventContent: "",
        createTime: "",
        updateTime: ""
      }
    };
  },
  methods: {
    cancel() {
      this.resetForm();
    },
    doSubmit(decision) {
      this.loading = true;
      this.audit(decision);
    },
    audit(decision) {
      this.params = {
        id: this.form.id,
        decision: decision,
        platformIbId: this.form.platformIbId,
        platformRegLink: this.form.platformRegLink,
        auditContent: this.form.eventContent
      };
      partnerAudit(this.params)
        .then(res => {
          this.resetForm();
          if(decision == 2){
            this.$notify({
              title: '审核通过',
              type: "success",
              duration: 2500
            });
          }else if(decision == 3){
            this.$notify({
              title: '审核拒绝',
              type: "success",
              duration: 2500
            });
          }

          this.loading = false;
          this.sup_this.init();
        })
        .catch(err => {
          this.loading = false;
          console.log(err.response.data.message);
        });
    },
    resetForm() {
      this.dialog = false;
      this.$refs["form"].resetFields();
      this.form = {
        id: "",
        siteId: "",
        partnerUserId: "",
        partnerUserName: "",
        cooperPlatformCode: "",
        platformIbId: "",
        platformRegLink: "",
        platformServerId: "",
        platformLogin: "",
        platformLoginPwd: "",
        checkStatus: "",
        eventContent: "",
        createTime: "",
        updateTime: ""
      };
    },
    getPlatformInfo(siteId) {
      this.params = { siteId: siteId, isDefault: 1 };
      getSrPlatformInfos(this.params)
        .then(res => {
          const data = res.content[0];
          this.form.platformIbId = data.ibNo;
          this.form.platformRegLink = data.regUrl + "?" + data.regLink;
        })
        .catch(err => {
          console.log(err.response.data.message);
        });
    }
  }
};
</script>

<style scoped>
</style>
